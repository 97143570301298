import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as Yup from 'yup'
import moment from 'moment'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { UsaStates } from 'usa-states'

import styles from './societyForm.module.css'

import IconPromotions from '../images/icon-promotions.inline.svg'
import IconEvents from '../images/icon-events.inline.svg'
import IconSweepstakes from '../images/icon-sweepstakes.inline.svg'
import IconPairings from '../images/icon-pairings.inline.svg'

const { states } = new UsaStates()
const referralSources = [
  'Facebook',
  'Instagram',
  'Referral',
  'In store',
  'Website',
  'Other',
]

const societyFormSchema = Yup.object().shape({
  firstname: Yup.string().required(),
  lastname: Yup.string().required(),
  email: Yup.string()
    .email()
    .required(),
  city: Yup.string(),
  state: Yup.string(),
  birthday: Yup.object().shape({
    year: Yup.string().matches(/^\d{4}$/, {
      message: 'Incorrect format. Must be YYYY',
      excludeEmptyString: true,
    }),
    month: Yup.string().matches(/^\d{2}$/, {
      message: 'Incorrect format. Must be MM',
      excludeEmptyString: true,
    }),
    day: Yup.string().matches(/^\d{2}$/, {
      message: 'Incorrect format. Must be DD',
      excludeEmptyString: true,
    }),
  }),
  referral: Yup.mixed()
    .oneOf(referralSources)
    .notRequired(),
  referral_other: Yup.string().notRequired(),
})

const SocietyForm = ({ data }) => {
  const { frontmatter } = data
  const [alert, setAlert] = useState(null)

  const submitForm = (values, { setSubmitting, resetForm }) => {
    setAlert(null)

    const birthdayInput = Object.values(values.birthday).some(function(value) {
      return value !== ''
    })
    const datePickerIsoFormat = `${values.birthday.year}-${values.birthday.month}-${values.birthday.day}`

    if (birthdayInput && !moment(datePickerIsoFormat).isValid()) {
      setAlert({
        type: 'error',
        message: <p>Invalid birthday format.</p>,
      })
      setSubmitting(false)
      return
    }

    let referral = values.referral
    switch (referral) {
      case 'Other':
        if (values.referral_other !== '') {
          referral = values.referral_other
        }
        break
      case 'Referral':
        if (values.referral_name !== '') {
          referral = values.referral_name
        }
        break
      default:
        break
    }

    const data = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      city: values.city,
      state: values.state,
      date_picker: birthdayInput ? datePickerIsoFormat : '',
      referral,
      kris_brand_engagement: true,
    }

    fetch(`${process.env.GATSBY_LAMBDA_API_URL}hubspot`, {
      method: 'POST',
      body: JSON.stringify({
        formGuid: 'bbbe7740-7f9c-4808-87ca-aef892d0ea5c',
        data,
      }),
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        }

        return Promise.reject(response)
      })
      .then(json => {
        const message = json.inlineMessage
          ? json.inlineMessage
          : 'Thank you for joining the KRIS Society!'
        setAlert({
          type: 'success',
          message: <p>{message}</p>,
        })
        resetForm()
      })
      .catch(error => {
        console.error('Error response', error)
        setAlert({
          type: 'error',
          message: <p>{error.statusText}</p>,
        })
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <div className="bg-paint-top-blue">
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <section className={styles.content}>
            <h1
              className={classNames('title', styles.title)}
              dangerouslySetInnerHTML={{
                __html: frontmatter.title.replace('\n', '<br />'),
              }}
            />
            <Formik
              initialValues={{
                firstname: '',
                lastname: '',
                email: '',
                city: '',
                state: '',
                birthday: {
                  year: '',
                  month: '',
                  day: '',
                },
                date_picker: '',
                referral: '',
                referral_name: '',
                referral_other: '',
              }}
              validationSchema={societyFormSchema}
              onSubmit={submitForm}
            >
              {({ values, isValid, isSubmitting }) => (
                <Form
                  className="form"
                  aria-label="KRIS Society signup form"
                  tabIndex={0}
                >
                  {alert && (
                    <div
                      className={classNames('alert', `alert--${alert.type}`)}
                      aria-live="assertive"
                    >
                      {alert.message}
                    </div>
                  )}
                  <div className="form__row-2col">
                    <div className="form__group">
                      <label htmlFor="input-firstname">
                        First Name <sup>*</sup>
                      </label>
                      <Field
                        id="input-firstname"
                        name="firstname"
                        className="input"
                      />
                      <ErrorMessage
                        name="firstname"
                        component="div"
                        className="input-error"
                        aria-live="assertive"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="input-lastname">
                        Last Name <sup>*</sup>
                      </label>
                      <Field
                        id="input-lastname"
                        name="lastname"
                        className="input"
                      />
                      <ErrorMessage
                        name="lastname"
                        component="div"
                        className="input-error"
                        aria-live="assertive"
                      />
                    </div>
                  </div>
                  <div className="form__group">
                    <label htmlFor="input-email">
                      Email <sup>*</sup>
                    </label>
                    <Field
                      id="input-email"
                      type="email"
                      name="email"
                      className="input"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="input-error"
                      aria-live="assertive"
                    />
                  </div>
                  <div className="form__row-2col">
                    <div className="form__group">
                      <label htmlFor="input-city">City</label>
                      <Field id="input-city" name="city" className="input" />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="input-error"
                        aria-live="assertive"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="input-state">State</label>
                      <Field
                        id="input-state"
                        name="state"
                        component="select"
                        className="input input--select"
                      >
                        <option value="">Select state</option>
                        {states.map((state, index) => (
                          <option key={index} value={state.name}>
                            {state.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="state"
                        component="div"
                        className="input-error"
                        aria-live="assertive"
                      />
                    </div>
                  </div>
                  <div className="form__row-2col">
                    <fieldset className="form__group">
                      <legend className="label">Birthday</legend>
                      <div className="form__row-3col form__row-3col--inline">
                        <div>
                          <label
                            htmlFor="input-birthday_year"
                            className="visuallyhidden"
                          >
                            Year
                          </label>
                          <Field
                            id="input-birthday_year"
                            name="birthday.year"
                            placeholder="YYYY"
                            className="input"
                          />
                          <ErrorMessage
                            name="birthday.year"
                            component="div"
                            className="input-error"
                            aria-live="assertive"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="input-birthday_month"
                            className="visuallyhidden"
                          >
                            Month
                          </label>
                          <Field
                            id="input-birthday_month"
                            name="birthday.month"
                            placeholder="MM"
                            className="input"
                          />
                          <ErrorMessage
                            name="birthday.month"
                            component="div"
                            className="input-error"
                            aria-live="assertive"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="input-birthday_day"
                            className="visuallyhidden"
                          >
                            Day
                          </label>
                          <Field
                            id="input-birthday_day"
                            name="birthday.day"
                            placeholder="DD"
                            className="input"
                          />
                          <ErrorMessage
                            name="birthday.day"
                            component="div"
                            className="input-error"
                            aria-live="assertive"
                          />
                        </div>
                      </div>
                    </fieldset>
                    <p className="form__group form__group--centered subtitle subtitle--small">
                      <br />
                      Tell us your birthday so we can celebrate with you!
                    </p>
                  </div>
                  <div className="form__group">
                    <label htmlFor="input-referral">
                      How did you hear about The KRIS Society?
                    </label>
                    <Field
                      id="input-referral"
                      name="referral"
                      component="select"
                      className="input input--select"
                    >
                      <option value="">Select source</option>
                      {referralSources.map((source, index) => (
                        <option key={index} value={source}>
                          {source}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="referral"
                      component="div"
                      className="input-error"
                      aria-live="assertive"
                    />
                  </div>
                  {values.referral === 'Referral' && (
                    <div className="form__group">
                      <label
                        htmlFor="input-referral_name"
                        className="visuallyhidden"
                      >
                        Referral Name
                      </label>
                      <Field
                        id="input-referral_name"
                        name="referral_name"
                        className="input"
                        placeholder="Name"
                      />
                      <ErrorMessage
                        name="referral_name"
                        component="div"
                        className="input-error"
                        aria-live="assertive"
                      />
                    </div>
                  )}
                  {values.referral === 'Other' && (
                    <div className="form__group">
                      <label
                        htmlFor="input-referral_other"
                        className="visuallyhidden"
                      >
                        Other Referral Source
                      </label>
                      <Field
                        id="input-referral_other"
                        name="referral_other"
                        className="input"
                        placeholder="Other source"
                      />
                      <ErrorMessage
                        name="referral_other"
                        component="div"
                        className="input-error"
                        aria-live="assertive"
                      />
                    </div>
                  )}
                  <button
                    className="button button--green button--wide"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    Join Us
                  </button>
                </Form>
              )}
            </Formik>
          </section>
          <aside
            aria-labelledby="society-highlights-heading"
            className={styles.aside}
            tabIndex={0}
          >
            <h2
              id="society-highlights-heading"
              className={classNames('subtitle', styles.subtitle)}
            >
              Why Join KRIS Society!?
            </h2>
            <ul className={styles.highlights}>
              <li>
                <IconPromotions />
                <p>
                  Be the FIRST to know about new and limited edition releases
                </p>
              </li>
              <li>
                <IconEvents />
                <p>
                  Society member only events and VIP access to tastings or local
                  events
                </p>
              </li>
              <li>
                <IconSweepstakes />
                <p>
                  Exclusive chances to win wine-related prizes and giveaways
                </p>
              </li>
              <li>
                <IconPairings />
                <p>
                  Delicious and easy food and wine pairings delivered right to
                  your inbox
                </p>
              </li>
            </ul>
          </aside>
        </div>
      </div>
    </div>
  )
}

SocietyForm.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SocietyForm
