import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import classNames from 'classnames'

import styles from './pastGiveaways.module.css'

import Image from './image'

const PastGiveaways = ({ items }) => {
  if (!items.length) {
    return null
  }

  const giveaways = Object.values(items)
  const latestGiveaway = giveaways.shift()

  return (
    <section
      className={classNames('section-gold', styles.root)}
      aria-labelledby="section-giveaways-heading"
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h2
            className={classNames('title title--white', styles.title)}
            id="section-giveaways-heading"
          >
            <em>Past</em> Giveaways
          </h2>
          <div className={styles.giveaways}>
            {latestGiveaway && (
              <figure className={styles.latestGiveaway}>
                <Image
                  node={latestGiveaway.featuredImage.src}
                  alt={latestGiveaway.featuredImage.alt}
                  className="framed-image"
                />
                <figcaption className={styles.giveawayTitle}>
                  {latestGiveaway.title}
                </figcaption>
              </figure>
            )}
            <div className={styles.giveawaysGrid}>
              {giveaways.map((giveaway, index) => (
                <figure key={index}>
                  <div className="framed-image">
                    <Image
                      node={giveaway.thumbnailImage.src}
                      alt={giveaway.thumbnailImage.alt}
                    />
                  </div>
                  <figcaption className={styles.giveawayTitle}>
                    {giveaway.title}
                  </figcaption>
                </figure>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

PastGiveaways.propTypes = {
  items: PropTypes.array.isRequired,
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        file(relativePath: { eq: "content/giveaways.json" }) {
          childContentJson {
            items {
              title
              featuredImage: image {
                src {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 456, maxHeight: 510) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                alt
              }
              thumbnailImage: image {
                src {
                  publicURL
                  childImageSharp {
                    fluid(
                      maxWidth: 220
                      maxHeight: 220
                      fit: CONTAIN
                      background: "#ffffff"
                    ) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                alt
              }
            }
          }
        }
      }
    `}
    render={data => <PastGiveaways items={data.file.childContentJson.items} />}
  />
)
