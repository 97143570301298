import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import classNames from 'classnames'
import Fade from 'react-reveal/Fade'

import styles from './social.module.css'

import ExternalLink from './externalLink'
import Image from './image'
import LinkIcon from './linkIcon'
import ChatIcon from '../images/icon-chat.inline.svg'
import LikesIcon from '../images/icon-likes.inline.svg'

const SectionSocial = ({ showHeader, title, socialMenu, instagrams }) => (
  <section
    id="social-media"
    className={styles.root}
    aria-labelledby="section-social-media"
  >
    <div className={styles.wrapper}>
      {showHeader && (
        <header className={styles.header}>
          <h2
            id="section-social-media"
            className={classNames('title', styles.title)}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {socialMenu.length > 0 && (
            <ul className={styles.socialMenu}>
              {socialMenu.map((item, index) => {
                return (
                  <li key={index}>
                    <ExternalLink href={item.url} className={item.classes}>
                      <LinkIcon label={item.title} />
                      <span className="visuallyhidden">{item.title}</span>
                    </ExternalLink>
                  </li>
                )
              })}
            </ul>
          )}
        </header>
      )}
      {instagrams.totalCount && (
        <ul className={styles.instagrams}>
          {instagrams.edges.map((edge, index) => {
            const item = edge.node

            return (
              <Fade key={item.id} delay={150 * index}>
                <li>
                  <ExternalLink
                    href={`https://www.instagram.com/p/${item.id}`}
                    className={styles.gram}
                  >
                    <Image node={item.localFile} alt={item.caption} />
                    <ul>
                      {item.likes && (
                        <li>
                          <LikesIcon />
                          <span className="visuallyhidden">Likes</span>
                          {item.likes}
                        </li>
                      )}
                      {item.comments && (
                        <li>
                          <ChatIcon />
                          <span className="visuallyhidden">Comments</span>
                          {item.comments}
                        </li>
                      )}
                    </ul>
                  </ExternalLink>
                </li>
              </Fade>
            )
          })}
        </ul>
      )}
    </div>
  </section>
)

SectionSocial.propTypes = {
  showHeader: PropTypes.bool,
  title: PropTypes.string.isRequired,
  socialMenu: PropTypes.array.isRequired,
  instagrams: PropTypes.object.isRequired,
}

const Social = ({ showHeader }) => (
  <StaticQuery
    query={graphql`
      {
        socialMenu: file(relativePath: { eq: "menus/social.json" }) {
          childMenusJson {
            items {
              classes
              title
              type
              url
            }
          }
        }
        allInstaNode(limit: 5, sort: { fields: [timestamp], order: DESC }) {
          totalCount
          edges {
            node {
              id
              likes
              comments
              mediaType
              timestamp
              caption
              localFile {
                childImageSharp {
                  fixed(width: 320) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <SectionSocial
        title="Connect with KRIS Wine <em>on social media</em>"
        socialMenu={data.socialMenu.childMenusJson.items}
        instagrams={data.allInstaNode}
        showHeader={showHeader}
      />
    )}
  />
)

Social.propTypes = {
  showHeader: PropTypes.bool,
}

Social.defaultProps = {
  showHeader: true,
}

export default Social
