import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SocietyForm from '../components/societyForm'
import PastGiveaways from '../components/pastGiveaways'
import Social from '../components/social'

export default ({ data }) => {
  return (
    <Layout>
      <SEO
        title={data.page.frontmatter.title}
        seoTitle={data.page.frontmatter.seo_title}
        description={data.page.frontmatter.seo_description}
      />
      <SocietyForm data={data.page} />
      <PastGiveaways />
      <Social showHeader={false} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        seo_title
        seo_description
      }
    }
  }
`
